<template>
  <div class="bx-footer__abo-teaser bx-form bx-typo--form">
    <div class="bx-footer__abo-teaser-image">
      <img :src="coverUrl"
           width="2"
           height="3"
           loading="lazy"
           data-pin-nopin="true"
           alt="Cover der aktuellen Ausgabe der Astrowoche"
           title="Cover der aktuellen Ausgabe der Astrowoche">
    </div>
    <div class="bx-footer__abo-teaser-inner-wrapper">
      <span class="bx-footer__abo-teaser-headline">
        ASTROWOCHE im Abo
      </span>
      <span class="bx-footer__abo-teaser-roofline">
        Erscheint wöchentlich
      </span>
      <a target="_blank"
         rel="noopener"
         href="https://www.bauer-plus.de/astrowoche-footer"
         class="bx-form__button">
        Hier geht's zum Abo
      </a>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    coverUrl () {
      return `${process.env.COVER_URL}astrowoche.jpg`
    }
  }
}
</script>
