<template>
  <div class="bx-header__logo-wrapper">
    <a class="bx-header__logo"
       title="astrowoche.wunderweib.de"
       href="/">
      <img src="/images/astrowoche/astrowoche-logo.png"
           alt="Astrowoche Logo"
           width="191"
           height="100">
    </a>

    <div class="bx-header__presented-by">
      <span>präsentiert von </span> <a href="https://www.wunderweib.de"
                                       rel="noopener"
                                       target="_blank">WUNDERWEIB.de</a>
    </div>
  </div>
</template>

<script>
export default {}
</script>
