<template>
  <!-- eslint-disable vue/no-unused-refs MIXIN -->
  <div v-if="!blockedForPage"
       id="starsign-slider-flickity-0"
       ref="flickityDiv"
       class="bx-slider bx-slider--starsign-slider bx-typo--starsign-slider">
    <div v-for="(item, index) in sliderSettings?.starsignSlider?.items"
         :id="`bx-js-starsign-slider-cell-${index}`"
         :key="index"
         class="bx-slider__cell">
      <a :href="item.url"
         :title="item.name"
         tabindex="-1">
        <svg><use :href="`/images/${brandFromStore}/starsign_sprite.svg#${item.name.toLowerCase()}`" /></svg>
        <span>{{ item.name }}</span>
      </a>
    </div>
  </div>
</template>

<script>
import { mapState } from 'pinia'

import { usePageStore } from '../../../stores/page'
import { useConfigStore } from '../../../stores/config'
import flickitySlider from '../../../mixins/flickity-slider'

export default {
  name: 'StarsignSlider',
  mixins: [flickitySlider],
  props: {
    sliderOptions: {
      type: Object,
      default: () => { return {} }
    }
  },
  computed: {
    // Some of these store props are also or only used by mixins
    ...mapState(useConfigStore, ['rsConfig']),
    ...mapState(usePageStore, ['viewportMetaModified', 'pageData', 'brandFromStore']),
    blockedForPage () {
      return ['/support', '/newsletter'].includes(this.pageData.pageMeta.url)
    }
  },
  async mounted () {
    if (!this.blockedForPage) {
      await this.initFlickitySlider(this.sliderOptions)
    }
  }
}
</script>
